export const buildNavbarMenu = (routes, solution, services) => {
  if (!routes[solution]) {
    return [];
  }
  const toReturn = [];
  for (const section of routes[solution]) {
    // hide sections that are not enabled at app level
    if (section.hideSection) {
      continue;
    }
    if (section.alwaysShowSection) {
      toReturn.push(section);
      continue;
    }

    const filteredSection = { ...section, children: [] };

    for (const route of section.children) {
      const filteredRoute = { ...route };
      // check if parent is related to a service OR parent has service enabled
      if (route.service && services.includes(route.service)) {
        filteredSection.children.push(filteredRoute);
        continue;
      }

      if (route.children) {
        // check if child is related to a service OR child has service enabled
        filteredRoute.children = route.children.filter(
          (child) =>
            !child.service ||
            (child.service && services.includes(child.service))
        );
        if (filteredRoute.children.length > 0) {
          filteredSection.children.push(filteredRoute);
        }
      }
    }

    if (filteredSection.children.length > 0) {
      toReturn.push(filteredSection);
    }
  }

  return toReturn;
};
